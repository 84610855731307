
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', {}, _createElement('product-card', { 'class': 'card card--product h-full card--product-compare relative flex' }, _createElement('div', { 'className': 'product-label-container absolute top-0 end' }, _createElement('div', { 'className': 'product-label-wrapper flex' }, this.on_sale ? _createElement('span', {
                    'className': 'product-label product-label--sale',
                    'key': '208'
                }, _createElement('svg', {
                    'className': 'icon icon--price_tag',
                    'width': '14',
                    'height': '14',
                    'viewBox': '0 0 16 16',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation'
                }, _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M7.59 1.34a1 1 0 01.7-.29h5.66a1 1 0 011 1v5.66a1 1 0 01-.3.7L7.6 15.5a1 1 0 01-1.42 0L.52 9.83a1 1 0 010-1.42l7.07-7.07zm6.36 6.37l-7.07 7.07-5.66-5.66L8.3 2.05h5.66v5.66z',
                    'fill-rule': 'evenodd'
                }), _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M9.7 6.3a1 1 0 101.42-1.42 1 1 0 00-1.41 1.41zM9 7a2 2 0 102.83-2.83A2 2 0 009 7z',
                    'fill-rule': 'evenodd'
                })), '\n          ', Math.round((this.compare_at_price - this.price) / this.compare_at_price * 100), '% off\n        ') : null)), _createElement('div', { 'className': 'card__media relative' }, _createElement('a', {
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'className': 'media block relative image-blend js-prod-link',
                    'tabIndex': '-1'
                }, _createElement('img', {
                    'className': 'img-fit card__main-image no-js-hidden',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'data-media-id': this.id,
                    'loading': this._rank <= 3 ? undefined : 'lazyload',
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), _createElement('div', { 'className': 'product-label-container absolute top-0 start' }, _createElement('div', { 'className': 'product-label-wrapper flex' }, this.out_of_stock ? _createElement('span', {
                    'className': 'product-label product-label--sold-out',
                    'key': '1543'
                }, 'Sold out') : null))), _createElement('div', { 'className': 'card__info-container flex flex-col flex-auto relative' }, _createElement('div', { 'className': 'card__info w-full' }, _createElement('div', { 'className': 'card__info-inner inline-block w-full' }, _createElement('p', { 'className': 'card__title font-bold mb-1' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'card-link text-current js-prod-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('p', { 'className': 'mb-1 text-sm text-theme-light' }, _createElement('span', mergeProps({ 'className': 'card__vendor' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), '\n            |\n            ', _createElement('span', mergeProps({ 'className': 'card__sku' }, { dangerouslySetInnerHTML: { __html: this.sku } }))), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '23370'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__default' }, this.price_varies ? _createElement('span', { 'key': '2819' }, 'From: ') : null, this.on_sale ? _createElement('s', {
                    'className': 'price__was',
                    'key': '2879'
                }, this.formatPrice(this.compare_at_price)) : null, _createElement('strong', { 'className': 'price__current' }, this.formatPrice(this.price)))))), _createElement('div', { 'className': 'card__add-to-cart_container' }, !this.out_of_stock ? _createElement('div', {
                    'className': 'badge badge-in-stock',
                    'key': '3173'
                }, _createElement('div', { 'className': 'icon-container' }, _createElement('svg', {
                    'className': 'svg-check',
                    'version': '1.1',
                    'id': 'Capa_1',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'xmlns:xlink': 'http://www.w3.org/1999/xlink',
                    'x': '0px',
                    'y': '0px',
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 405.272 405.272',
                    'style': { enableBackground: 'new 0 0 405.272 405.272' },
                    'xml:space': 'preserve'
                }, _createElement('path', { 'd': 'M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836 c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064 c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z' }))), _createElement('span', {}, 'In stock')) : null, _createElement('div', { 'className': 'card__quick-add mob:card__quick-add--below desktop:card__quick-add--below' }, _createElement('product-form', {}, _createElement('div', {
                    'className': 'alert mb-8 bg-error-bg text-error-text js-form-error text-start',
                    'role': 'alert',
                    'hidden': true
                }), _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'quick-add-' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'js-product-form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), this.out_of_stock || !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('button', {
                    'className': 'btn btn--primary w-full',
                    'data-add-to-cart-text': 'Add to cart',
                    'name': 'add',
                    'aria-haspopup': 'dialog',
                    'disabled': this.out_of_stock,
                    'key': '4655'
                }, _createElement('span', { 'className': 'quick-add-btn-text' }, !this.out_of_stock ? 'Add to cart' : 'Sold out')) : null)), !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('button', {
                    'type': 'button',
                    'className': 'btn btn--primary w-full js-quick-add',
                    'aria-haspopup': 'dialog',
                    'data-product-url': this.url,
                    'data-product-default-variant': 'false',
                    'data-quick-add-listener-added': 'true',
                    'key': '5094'
                }, _createElement('span', { 'className': 'quick-add-btn-text block pointer-events-none' }, 'Choose options')) : null)))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'h4 mt-10 cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'ul',
        { 'className': 'slider slider__grid grid grid-flow-col auto-cols-2 small-cards-mobile md:auto-cols-3 lg:auto-cols-4 xl:auto-cols-5 small-cards-desktop gap-x-theme gap-y-8 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []