import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  pagination: {
    edgeRange: 0,
    centralRange: 2,
  },
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets.filter(
      (w) => !['CategoryPage', 'SearchBox'].includes(w.name),
    ),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { replace: 'header form.search' },
      onDropdownItemsReceived: () =>
        shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').onDropdownItemsReceived,
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () => window.document.querySelector('store-header')?.classList.add('search-is-collapsed'),
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: { firstChildOf: '.header__grid .header__icons  .js-show-search' },
      ...shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').onDropdownItemsReceived,
      template: 'searchBox/dialogButtonMobile',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'CategoryPage',
      ...shopifyEnterpriseGenericDefaults.Widgets.find((w) => 'CategoryPage' === w.name).visibleIf,
    },
  ],
};
